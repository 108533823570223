export default class LazyVideo {
    $el: HTMLElement;

    constructor($el: HTMLElement) {
        this.$el = $el;

        if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver(function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.target instanceof HTMLVideoElement) {
                        if (entry.isIntersecting) {
                            for (let source in entry.target.children) {
                                let entrySource = entry.target.children[source] as HTMLSourceElement;

                                if (typeof entrySource.tagName === 'string' && entrySource.tagName === 'SOURCE') {
                                    // Set src to data-src value and remove data-src attribute
                                    entrySource.src = entrySource.dataset.src || '';
                                    delete entrySource.dataset.src;
                                }
                            }

                            entry.target.classList.remove('js-lazyVideo');
                            entry.target.load();
                            observer.unobserve(entry.target);
                        }
                    }
                });
            });

            observer.observe(this.$el);
        }
    }
}
