export default class Video {
    $el: HTMLElement;
    $toggleAudio: HTMLButtonElement;
    $togglePlayback: HTMLButtonElement;
    $video: HTMLVideoElement;
    isStopOnPause: boolean;
    pointerDownPosition: { x: number; y: number };

    constructor($el: HTMLElement) {
        this.$el = $el;

        this.$togglePlayback = this.$el.querySelector('button')!;
        this.$video = this.$el.querySelector('video')!;
        this.isStopOnPause = this.$el.dataset.hasOwnProperty('videoStopOnPause');

        // Add playback toggle button
        this.$togglePlayback = document.createElement('button');
        this.$togglePlayback.classList.add('video__togglePlayback');
        this.$el.append(this.$togglePlayback);

        // Add audio toggle button
        this.$toggleAudio = document.createElement('button');
        this.$toggleAudio.classList.add('video__toggleAudio');
        this.$el.append(this.$toggleAudio);

        this.initListeners();
    }

    initListeners(): void {
        this.$toggleAudio.addEventListener('pointerup', this.onClickToggleAudio.bind(this), false);
        this.$togglePlayback.addEventListener('pointerdown', (e) => {
            this.pointerDownPosition = { x: e.clientX, y: e.clientY };
        });
        this.$togglePlayback.addEventListener('pointerup', this.onClickTogglePlayback.bind(this), false);
    }

    onClickToggleAudio(): void {
        this.$video.muted = this.isMuted = !this.isMuted;
    }

    onClickTogglePlayback(e): void {
        // Detect dragging
        if (Math.abs(e.clientX - this.pointerDownPosition.x) > 5) {
            return;
        }

        if (Math.abs(e.clientY - this.pointerDownPosition.y) > 5) {
            return;
        }

        if (this.isPlaying) {
            this.$video.pause();

            if (this.isStopOnPause) {
                this.$video.currentTime = 0;
            }
        } else {
            this.$video.play();
        }

        this.isPlaying = !this.isPlaying;
    }

    /**
     * Getters & setters
     */

    get isMuted(): boolean {
        return this.$el.classList.contains('is-muted');
    }

    set isMuted(isMuted: boolean) {
        this.$el.classList.toggle('is-muted', isMuted);
    }

    get isPlaying(): boolean {
        return this.$el.classList.contains('is-playing');
    }

    set isPlaying(isPlaying: boolean) {
        this.$el.classList.toggle('is-playing', isPlaying);
    }
}
