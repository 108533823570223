import gsap from 'gsap';

export default class Nav {
    $el: HTMLElement;
    height: number;
    isFixed: boolean;
    isScrolled: boolean;
    scrollDirection: -1 | 0 | 1;
    scrollY: number;

    constructor($el: HTMLElement) {
        this.$el = $el;

        this.height = this.$el.offsetHeight;
        this.isFixed = false;
        this.isScrolled = false;
        this.scrollDirection = 0;
        this.scrollY = window.scrollY;

        this.initListeners();
    }

    initListeners(): void {
        this.$el.addEventListener('transitionend', this.onTransitionEnd.bind(this), false);

        window.addEventListener('scroll', this.onScroll.bind(this), false);
        window.addEventListener('resize', this.update.bind(this), false);
    }

    onScroll(): void {
        const isFixed = window.scrollY > this.height;
        const isScrolled = window.scrollY > 0;
        const scrollDirection = window.scrollY < this.scrollY ? -1 : 1;

        if (isFixed !== this.isFixed) {
            this.$el.classList.toggle('is-fixed', isFixed);
            this.isFixed = isFixed;
        }

        if (isScrolled !== this.isScrolled) {
            this.$el.classList.toggle('is-scrolled', isScrolled);
            this.isScrolled = isScrolled;
        }

        if (scrollDirection !== this.scrollDirection) {
            this.$el.classList.toggle('is-scrollingDown', scrollDirection === 1);
            this.$el.classList.toggle('is-scrollingUp', scrollDirection === -1);
            this.scrollDirection = scrollDirection;
        }

        this.scrollY = window.scrollY;
    }

    onTransitionEnd(e: Event): void {
        this.$el.classList.toggle('is-hidden', this.$el.getBoundingClientRect().y < 0);
    }

    update(): void {
        this.height = this.$el.offsetHeight;
    }
}
